.week {
    font-size: 14px;
}

.day {
    font-size: 11px;
}

.pagination .active{
    background: #56839f !important;
}

.pagination{
    justify-content: flex-end;
}

.filter-item{
    cursor: pointer;
    flex: 0 0 auto;
    width: 12.26%;
}

.filter-item-search{
    flex: 0 0 auto;
    width: 12.5%;
    padding-left: 8px;
    padding-right: 8px;
}

.note-container{
    width: 323px;
    padding: 12px;
    border-radius: 4px;
    background: #fff;
    position: relative;
}

.note-container .title{
    font-size: 16px;
    color: #333333;
    padding: 0px !important;
    line-height: .4;
}

.note-container .title.blue{
    color: #0000FF !important;
}

.note-container .subtitle{
    font-size: 13px;
    color: #868383;
    padding: 0px !important;
    line-height: .4;
}

.note-container .url{
    font-size: 13px;
    color: #0000FF;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 16px;
    font-weight: 500;
}

.note-container .close-icon{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.comment-count{
    background-color: rgba(12, 54, 104, 1);
    font-size: 11px;
    position: absolute;
    height: 18px;
    width: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    font-weight: 700;
}

.filter-wrapper{
    position: relative !important;
}

.date-filter-container{
    width: 95px;
    padding: 10px;
    position: absolute;
    background-color: #fff;
    z-index: 2;
    transition: all .5s ease-in-out;
    max-height: 0px;
    overflow: hidden;
    padding: 0px
}

.date-filter-container.show{
    max-height: 999px;
}

.date-filter-container .date-filter-content{
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
}

.date-filter-container .date-filter-content.selected{
    background-color: #cccaca;
}

.date-filter-container .date-filter-content:not(:last-child){
    margin-bottom: 3px;
}

.date-filter-container .date-filter-content .code,
.date-filter-container .date-filter-content .count{
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    width:50%;
}

.calender-schedule-container{
    overflow-x: auto;
}

.unauthorized{
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.unauthorized>p{
    font-size: 25px;
    color: #868383;
    line-height: .5;
}

.dragging-item{
    z-index: -10;
}

.dragging-item .dragging-active{
    visibility: visible !important;
}

.dragging-active{
    opacity: .8;
    visibility: hidden;
}

.task-box{
    cursor: pointer;
    font-size: 11px;
}

.loading-overlay{
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.3);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 20;
    left: 0;
}

.back-to-list-btn{
    border-radius: 5px;
    font-size: 16px;
    color: white;
    background-color: #56839f;
    border-color: #56839f;
    white-space: nowrap;
    vertical-align: middle;
    padding: 0.65rem 1.25rem;
    font-size: 1rem;
    line-height: 1.25;
    outline: none;
    border: none;
}

.back-to-list-btn:hover{
    background-color: #70a5ce;
    border-color: #70a5ce;
    color: #fff;
}

.custom-sticky-position{
    position: sticky;
    top: 0;
    z-index: 100;
}

.filter-item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.full-screen-loader-container{
    position: relative;
}

.full-screen-loader-container .loader{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 101;
    background-color: rgba(0, 0, 0, 0.685);
    display: flex;
    align-items: center;
    justify-content: center;
}